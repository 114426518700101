import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import fb from './ostium/firebase';
import Container from '@mui/material/Container';
import PageSelect from './components/PageSelect';
import Avatar from '@mui/material/Avatar';

//local imports
import BarButton from './components/BarButton';
import TabPlugin from './components/TabPlugin';
import TabFirmware from './components/TabFirmware';
import TabUniverInter from './components/TabUniverInter';
import TabSupport from './components/TabSupport';
import NeTheme from './components/NeTheme';
import Footer from './components/Footer';
import TabCookies from './components/Cookies';
import TabEula from './components/Eula';
import TabPrivacy from './components/Privacy';
import TabTanc from './components/Tanc';
import TabValidate from './components/TabValidate';
import TabRedirect from './components/TabRedirect';
import TabReset from './components/TabReset';
import TabRedeem from './components/TabRedeem';
import TabLogin from './components/TabLogin';
import TabResetOnClick from './components/TabResetOnClick';
import Contact from './components/Contact';
import TabFaq from './components/TabFaq';

import {TypeOcrB1} from './components/Types';
import {
  isWhitelisted,
  isValid,
  PAGE_LOGIN,
  PAGE_TANC,
  PAGE_EULA,
  PAGE_COOKIES,
  PAGE_PRIVACY,
  PAGE_VALIDATE,
  PAGE_RESET,
  PAGE_RESET_CLICK,
  PAGE_FIRMWARE,
  PAGE_PLUGIN,
  PAGE_UNIVER,
  PAGE_REDEEM,
  PAGE_REDIRECT,
  PAGE_CONTACT,
  PAGE_SUPPORT,
  PAGE_FAQS,
} from './const';
import getUserInfo from './ostium/UserInfo';

interface AppProps {}

export default function App(props: AppProps) {
  const [currentTab, _setCurrentTab] = React.useState('login');
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  function isAvailable(s: string) {
    return isWhitelisted(s) || getUserInfo().loggedIn;
  }
  function setCurrentTab(s: string) {
    console.log('setCurrentTab', s);
    if (!isValid(s)) {
      console.log(s, 'tab is not valid');
      return;
    }
    if (!isAvailable(s)) {
      console.log(s, 'tab is not whitelisted');
      return;
    }
    _setCurrentTab(s);
  }

  return (
    <NeTheme>
      <div className="App">
        <main>
          <Container
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 2,
            }}
          >
            <Button
              variant="contained"
              color="error"
              style={{alignSelf: 'left'}}
              sx={{boxShadow: 0}}
              startIcon={
                <Avatar
                  src={'logo.png'}
                  variant="square"
                  sx={{width: 160, height: 65}}
                />
              }
              onClick={() =>
                (window.location.href = 'https://www.noiseengineering.us')
              }
            />
            <Button
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              color="primary"
              href="https://www.noiseengineering.us/products/dystorpia "
            >
              <TypeOcrB1>
                New! Dystorpia, our futuristic fuzz pedal. Shipping now!
              </TypeOcrB1>
            </Button>
          </Container>

          <Container
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              flexShrink: 2,
            }}
            sx={{mt: 3}}
          >
            <BarButton
              name="Firmware"
              enabled={isAvailable(PAGE_FIRMWARE)}
              onClick={() => {
                setCurrentTab(PAGE_FIRMWARE);
              }}
            />
            <BarButton
              name="Univer Inter"
              enabled={isAvailable(PAGE_UNIVER)}
              onClick={() => {
                setCurrentTab(PAGE_UNIVER);
              }}
            />
            <BarButton
              name="Plugins"
              enabled={isAvailable(PAGE_PLUGIN)}
              onClick={() => {
                setCurrentTab(PAGE_PLUGIN);
              }}
            />
            <BarButton
              name="Newsletter"
              enabled={true}
              onClick={() => {
                window.open(
                  'https://www.noiseengineering.us/pages/newsletter-sign-up',
                  '_blank'
                );
              }}
            />
            <BarButton
              name="Support"
              enabled={true}
              onClick={() => {
                setCurrentTab(PAGE_SUPPORT);
              }}
            />
            <BarButton
              name={isLoggedIn ? 'Log Out' : 'Log In'}
              enabled={true}
              onClick={() => {
                if (isLoggedIn) {
                  fb.auth.signOut();
                } else {
                  setCurrentTab(PAGE_LOGIN);
                }
              }}
            />
          </Container>

          <PageSelect current={currentTab} index={PAGE_LOGIN}>
            <TabLogin
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_FIRMWARE}>
            <TabFirmware />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_SUPPORT}>
            <TabSupport setCurrentTab={setCurrentTab} />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_UNIVER}>
            <TabUniverInter />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_PLUGIN}>
            <TabPlugin />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_TANC}>
            <TabTanc
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_EULA}>
            <TabEula
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_PRIVACY}>
            <TabPrivacy
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_COOKIES}>
            <TabCookies
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_VALIDATE}>
            <TabValidate
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_REDIRECT}>
            <TabRedirect />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_RESET}>
            <TabReset
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_RESET_CLICK}>
            <TabResetOnClick
              setPage={(s: string) => {
                setCurrentTab(s);
              }}
            />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_REDEEM}>
            <TabRedeem />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_FAQS}>
            <TabFaq setCurrentTab={setCurrentTab} />
          </PageSelect>

          <PageSelect current={currentTab} index={PAGE_CONTACT}>
            <Contact setCurrentTab={setCurrentTab} />
          </PageSelect>

          <Footer
            setTanc={() => {
              setCurrentTab(PAGE_TANC);
            }}
            setEula={() => {
              setCurrentTab(PAGE_EULA);
            }}
            setPriv={() => {
              setCurrentTab(PAGE_PRIVACY);
            }}
            setCook={() => {
              setCurrentTab(PAGE_COOKIES);
            }}
            setPage={(s: string) => {
              setCurrentTab(s);
            }}
          />
        </main>
      </div>
    </NeTheme>
  );
}
